import * as React from 'react';

import { cn } from '@/lib/utils';
import { Label } from './label';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

const InputRow = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn('flex items-center gap-4', className)}
    {...props}
  />
));
InputRow.displayName = 'InputRow';

const Input = React.forwardRef<
  HTMLInputElement,
  InputProps & {
    label?: string;
    vertical?: boolean;
    caption?: React.ReactNode;
  }
>(({ className, type, vertical = true, caption, name, ...props }, ref) => {
  const input = (
    <input
      type={type}
      className={cn(
        'box-border flex h-9 w-full rounded-sm border border-input bg-transparent px-3 py-1 shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-text-disabled disabled:cursor-not-allowed disabled:opacity-50',
        className
      )}
      ref={ref}
      name={name}
      {...props}
    />
  );

  if (props.label) {
    return (
      <div className="first:pt-0 last:pb-0 py-2">
        <div className="pb-1">
          <Label>{props.label}</Label>
        </div>
        {caption && <div>{caption}</div>}
        {input}
      </div>
    );
  }
  return input;
});
Input.displayName = 'Input';

export { Input, InputRow };
